<template>
  <div class="login-wrapper">
    <div class="account-pages">
      <div class="container">
        <div class="row align-items-center justify-content-center card-login">
          <div class="col-md-8 col-lg-6 col-xl-5 col-card-login">
            <div class="card card-login card-login-width">
              <v-row no-gutters>
                <v-col cols="12">
                  <div class="app-title">
                    <div class="middle login-heading">Smart Lighting Solution</div>
                  </div>
                </v-col>
              </v-row>
              <div class="card-body shadow">
                <v-form
                  ref="loginForm"
                  @submit.prevent="submit"
                  v-model="valid"
                  autocomplete="off"
                >
                  <v-row no-gutters class="row-body-spacing">
                    <v-col cols="4" class="login-label">
                      <span class="login-color login-title">Username:</span>
                    </v-col>
                    <v-col cols="8" class="login-text-field">
                      <v-text-field
                        label=""
                        class="login-input bg-login-input input-text"
                        v-model="userName"
                        required
                        outlined
                        filled
                        single-line
                        placeholder="username"
                        :rules="userValidation"
                        autocomplete="off"
                      ></v-text-field>
                      <div
                        class="error-message error-message-pink error-message-size"
                        v-if="userValidationMsg"
                      >
                        {{ userValidationMsg }}
                      </div>
                    </v-col>
                  </v-row>
                  <v-row no-gutters class="row-body-spacing">
                    <v-col cols="4" class="login-label">
                      <span class="login-color login-title">Password:</span>
                    </v-col>
                    <v-col cols="8" class="login-text-field">
                      <v-text-field
                        autocomplete="off"
                        label=""
                        class="login-input bg-login-input input-text"
                        v-model="password"
                        min="8"
                        required
                        outlined
                        filled
                        single-line
                        placeholder="password"
                        :type="showPass ? 'text' : 'password'"
                        :rules="passwordValidation"
                      >
                      </v-text-field>
                      <div
                        class="error-message error-message-pink error-message-size"
                        v-if="passValidationMsg"
                      >
                        {{ passValidationMsg }}
                      </div>
                    </v-col>
                  </v-row>
                  <div
                    id="error-text"
                    class="error-message error-message-pink error-message-size error-message-width"
                    v-if="errorText !== '' && !passValidationMsg && !userValidationMsg"
                  >
                    {{ errorText }}
                  </div>
                  <div class="row center submit-row">
                    <button
                      type="submit"
                      :class="{
                        'no-cursor': !valid,
                      }"
                      class="button-login login-hover button-text"
                    >
                      Login
                    </button>
                  </div>
                </v-form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-copyright">
      <span>Powered by Panasonic</span>
    </div>
    <!-- Message popup -->
    <v-dialog
      v-model="messageDialog"
      max-width="500"
      content-class="popup-global-reset-password"
    >
      <v-card id="global-reset-password" class="dialog_content">
        <v-card-title style="background-color: #111111;"></v-card-title>
        <v-card-text
          ><v-row no-gutters>
            <v-col cols="12" class="message_content">
              <div class="message_content_detail" v-html="apiMessage"></div>
            </v-col>
            <v-col cols="12" class="close_btn_content">
              <button
                class="btn btn-primary btn_black"
                :ripple="false"
                @click="closeMessage"
              >
                OK
              </button>
            </v-col>
          </v-row></v-card-text
        >
      </v-card>
    </v-dialog>
    <!-- End Message popup -->
  </div>
  
</template>

<style src="../assets/css/login.css"></style>

<script>
import { auth } from "../services/";
export default {
  data() {
    return {
      ktc_version: "Smart Lighting Solution Ver.2.0.0 ©2023",
      showPass: false,
      userName: "",
      password: "",
      errorText: "",
      warningMsg: "",
      remainingLockMsg: "",
      lockMsg: "",
      userValidationMsg: "",
      passValidationMsg: "",
      errUserCount: 0,
      errPassCount: 0,
      timeout: 2000,
      valid: false,
      countAttempt: 5,
      t: null,
      messageDialog: false,
      apiMessage: "",
    };
  },
  async created() {
    this.ktc_version = await this.getVersion();
  },
  computed: {
    userValidation() {
      return [
        (v) => {
          if (this.errUserCount == 0) {
            this.errUserCount++;
            return false;
          }
          if (!v) {
            this.valid = false;
            this.userValidationMsg = "Username is required";
            return false;
          } else {
            this.userValidationMsg = "";
            return true;
          }
        },
      ];
    },
    passwordValidation() {
      return [
        (v) => {
          if (this.errPassCount == 0) {
            this.errPassCount++;
            return false;
          }
          if (!v) {
            this.valid = false;
            this.passValidationMsg = "Password is required";
            return false;
          } else {
            this.passValidationMsg = "";
            return true;
          }
        },
      ];
    },
  },
  methods: {
    validUserName() {
      if (!this.userName) {
        this.valid = false;
        this.userValidationMsg = "Username is required";
        return false;
      }
      this.userValidationMsg = "";
      return true;
    },
    validPassword() {
      if (!this.password) {
        this.valid = false;
        this.passValidationMsg = "Password is required";
        return false;
      }
      this.passValidationMsg = "";
      return true;
    },
    submit() {
      clearTimeout(this.t);
      this.errorText = "";
      if (this.validUserName() && this.validPassword()) {
        const username = this.userName;
        const password = this.password;
        auth
          .login({ username, password })
          .then((res) => {
            if (res.status == 200 && res.data.success) {
              if (res?.data?.data?.token) {
                this.$store
                  .dispatch("login", res.data.data)
                  .then(() => (window.location.href = "/"));
              } else if (res?.data?.data?.id) {
                this.apiMessage = "Password has been reset to default successfully";
                if (res?.data?.message) {
                  this.apiMessage = "Password has been successfully reset to 12345678@X";
                }
                this.messageDialog = true;
              } else {
                if (res?.data?.data?.message) {
                  this.errorText = "Incorrect username and/or password.";
                }
              }
            } else {
              throw res;
            }
          })
          .catch((err) => {
            if (err?.response?.data?.message) {
              this.countAttempt = 0;
              this.errorText = "Incorrect username and/or password.";
            }
          });
      }
    },
    closeMessage() {
      this.messageDialog = false;
      location.reload();
    },
  },
};
</script>

<style scoped>
#global-reset-password.dialog_content{
  background-color: #111111; 
  border-radius: 8px !important; 
  border: 1px solid #b1b2b3;
}

#global-reset-password.dialog_content .v-card__title{
  padding: 20px 24px;
  justify-content: center;
  font-weight: bold;
}

#global-reset-password.dialog_content .v-card__text{
  margin-top: 0;
  padding: 0 24px 24px;
}

#global-reset-password.dialog_content .v-card__text .message_content{
  padding-left: 30px; 
  padding-right: 30px; 
  text-align: center;
  font-size: 20px;
}
#global-reset-password.dialog_content .v-card__text .message_content .message_content_detail{
  padding: 0 10px;
  line-height: 24px;
}

#global-reset-password.dialog_content .v-card__text .close_btn_content{
  text-align: center;
  padding-top: 40px;
}

#global-reset-password.dialog_content .v-card__text .close_btn_content .btn_black{
  background-color: #010101;
  border-color: #b1b2b3;
  border-radius: 5px;
  padding: 8px 100px;
}

#global-reset-password.dialog_content .v-card__text .close_btn_content .btn_black:hover{
  background-color: #292929;
}
</style>
